import React from "react";

const ContactArea = () => {
  return (
    <>
      <section id="contact_main_arae" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section_heading_center">
                <h2>Fale conosco</h2>
              </div>
            </div>
          </div>
          <div className="contact_main_form_area_two">
            <div className="row">
              <div className="col-lg-8">
                <div className="contact_left_top_heading">
                  <h2>
                   Você tem alguma dúvida? Entre em contato conosco para obter qualquer suporte
                  </h2>
                  <h3>Envie uma mensagem</h3>
                  <p>
                    Dentro de 3h entraremos em contato com uma resposta
                  </p>
                </div>
                <div className="contact_form_two">
                  <form action="mailto:comercial@itravel.ao" id="contact_form_content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Primeiro nome*"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Sobre nome*"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Numero de telefone*"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            className="form-control bg_input"
                            rows="5"
                            placeholder="Mensagem"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button type="button" className="btn btn_theme btn_md">
                            Enviar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contact_two_left_wrapper">
                  <h3>Contactos</h3>
                  <div className="contact_details_wrapper">
                    <div className="contact_detais_item">
                      <h4>Linha de apio ao cliente</h4>
                      <h3>
                        <a href="tel:+01-234-567-890">+244 936 500 717</a>
                      </h3>
                    </div>
                    <div className="contact_detais_item">
                      <h4>Email</h4>
                      <h3>
                        <a href="mailto:support@iTravelao.com">
                          support@iTravelao.com
                        </a>
                      </h3>
                    </div>
                    <div className="contact_detais_item">
                      <h4>Horarios</h4>
                      <h3>Segunda-Domingo : 24 horas</h3>
                    </div>
                    <div className="contact_map_area">
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.8421194093235!2d13.229880539101822!3d-8.897707770854637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f45064bfb915%3A0xad5ed5af708aa488!2sShopping%20Atrium%20Nova%20Vida!5e0!3m2!1spt-PT!2sao!4v1719667200214!5m2!1spt-PT!2sao"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
