import loader from "../../assets/img/loader/loader.gif"
import "./loader.css"


function Loader({state}){

    return(

        <>

            {state && (
            <div className="loader_container">
                <div>
                    <img src={loader} alt="image loader" />
                </div>
            </div>
            )
        }
        </>
        
    )

}

export default Loader;