import img1 from '../../../assets/img/icon/world.png'
import img2 from '../../../assets/img/icon/walte.png'
import img3 from '../../../assets/img/icon/star.png'
import img4 from '../../../assets/img/icon/persentis.png'





export const ServiceData = [
    {
        img: img1,
        heading: "Melhores serviços",
        para: `Oferecemos os melhores serviços de viagem para garantir que sua experiência seja inesquecível. Nossa equipe está sempre pronta para ajudar com qualquer necessidade que você possa ter.`
    },
   
    {
        img: img2,
        heading: "Pagamento confiável",
        para: `Disponibilizamos métodos de pagamento seguros e confiáveis para que você possa fazer suas transações com tranquilidade e confiança. A sua segurança é nossa prioridade.`
    },
   
    {
        img: img3,
        heading: "Melhor infraestrutura",
        para: `Contamos com uma infraestrutura de ponta para oferecer conforto e conveniência em todas as etapas da sua viagem. Desde a reserva até a estadia, cuidamos de tudo para você.`
    },
   
    {
        img: img4,
        heading: "Ofertas incríveis",
        para: `Aproveite as melhores ofertas e descontos exclusivos em passagens e reservas de hotéis. Na iTravelao, estamos sempre buscando as melhores oportunidades para você viajar mais e pagar menos.`
    },
   
]
