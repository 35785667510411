import "./style.css"


function BookingTour({handleInputChange, index, pecent, error, fields, currentPage}) {


    const field= fields[currentPage]


  return (

    <div className="booking_tour_form" style={{transform: `translateX(-${pecent}%)`}} >
                 
                  <div className="tour_booking_form_box" >

                    <div className="form-group mb-3">
                      <label htmlFor="firstName">Primeiro Nome</label>
                      <input
                      onChange={(e) => handleInputChange(e)}
                      type="text" name='firstName'
                      className="form-control"
                      id={index}
                      />
                      {(error.status && !field.firstName)&&<span className="error-message text-danger">{error.msg}</span>}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="lastName">Sobrenome</label>
                      <input
                      onChange={(e) => handleInputChange(e)}
                      name='lastName'
                      type="text"
                      className="form-control"
                      id={index}/>
                      {(error.status && !field.lastName)&& <span className="error-message text-danger">{error.msg}</span>}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="dateOfBirth">Data de Nascimento</label>
                      <input
                      onChange={(e) => handleInputChange(e)}
                      name='dateOfBirth'
                      type="date"
                      className="form-control"
                      id={index} 
                      />
                      {(error.status && !field.dateOfBirth)&& <span className="error-message text-danger">{error.msg}</span>}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="gender">Gênero</label>
                      <select
                      onChange={(e) => handleInputChange(e)}
                      name='gender'
                      className="form-control"
                      id={index} 
                      >
                        <option disabled value="">Selecione</option>
                        <option value="MALE">Masculino</option>
                        <option value="FEMALE">Feminino</option>
                        <option value="OTHER">Outro</option>
                      </select>
                      {(error.status && !field.gender) && <span className="error-message text-danger">{error.msg}</span>}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="email">Endereço de Email</label>
                      <input
                      onChange={(e) => handleInputChange(e)}
                      name='email'
                      type="email"
                      className="form-control"
                      id={index}
                      />
                      {(error.status && !field.email)&& <span className="error-message text-danger">{error.msg}</span>}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="phoneNumber">Número de Telefone</label>
                      <input
                      onChange={(e) => handleInputChange(e)}
                      name='phoneNumber'
                      type="tel"
                      className="form-control"
                      id={index}
                      />
                      {(error.status && !field.phoneNumber )&& <span className="error-message text-danger">{error.msg}</span>}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="passportNumber">Número do Passaporte</label>
                      <input onChange={(e) => handleInputChange(e)} type="text" className="form-control" id={index} name={"passportNumber"}/>
                      {(error.status && !field.passportNumber )&& <span className="error-message text-danger">{error.msg}</span>}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="passportIssuanceDate">Data de Emissão do Passaporte</label>
                      <input
                      onChange={(e) => handleInputChange(e)} 
                      type="date"
                      className="form-control"
                      id={index}
                      name={"passportIssuanceDate"}
                      />
                      {(error.status && !field.passportIssuanceDate)&& <span className="error-message text-danger">{error.msg}</span>}
                    </div>

                    {/* Mais campos podem ser adicionados conforme necessário */}

                  </div>
                </div>
  )
}

export default BookingTour