
import img1 from '../../../assets/img/imagination/imagination1.png'
import img2 from '../../../assets/img/imagination/imagination2.png'
import img3 from '../../../assets/img/imagination/imagination3.png'



export const DiscoverData = [
    {
        img: img1,
        heading: "7% para sempre",
        span:"Pela Taag",
    },
    {
        img: img2,
        heading: "Os melhores destinos do mundo",
        span:"Pelo Mundo",
    },
    {
        img: img3,
        heading: "Resorts de Luxo",
        span:"melhore ideia",
    },
]