import React from 'react'
// import ComonBanner
import CommonBanner from '../component/Common/CommonBanner'
// import BookingConfirmationArea
import BookingConfirmationArea from '../component/BookingConfirmation'
import BookingForm from '../component/BookingForm'

import { useLocation } from 'react-router-dom';


const BookingConfirmation = () => {

  const location = useLocation();
  const { state } = location;

  return (
    <>
        <CommonBanner heading="Booking Confirmation" pagination="Booking Confirmation"/>
        <BookingForm data={state}/>
    </>
  )
}

export default BookingConfirmation