// Modal.js

import React from 'react';
import Modal from 'react-modal';
import './style.css';

Modal.setAppElement('#root'); // Para acessibilidade, define o elemento raiz da aplicação

const CustomModal = ({ isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div className="modal-content">
        <h2>Reserva</h2>
        <p>Conteúdo do modal de reserva...</p>
        <button className="btn-close" onClick={closeModal}>Fechar</button>
      </div>
    </Modal>
  );
};

export default CustomModal;
