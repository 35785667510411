
import "./style.css"
function MyPagination({sizePerson, currentPage}) {

   return( <div className="container_pagination" style={{display:'flex', justifyContent: "flex-end", gap: '1rem'}}>

        {
        Array(parseInt(sizePerson)).fill('').map((_, index)=>(
            <button
            key={index}
            value={index}
            className={`${Number(currentPage) === index? 'btn-light disabled text-primary' : null} btn btn-small btn-primary disabled`}
            type="button"
            >{index+1}
            </button>

        ))
        }
</div>)
    

}

export default MyPagination;

