import axios from 'axios'
import React, {useEffect, useState} from 'react'
import FlightCard from '../../FligthCard'

import "./index.css"
import Loader from '../../../layout/loader/Loader'


const SearchForm = () => {
const [originSearch, setOriginSearch] = useState('')
const [origin, setOrigin] = useState({code: ''})

const [destinationSearch, setDestinationSearch] = useState('')
const [destination, setDestination] = useState({code: ''})
const [cities, setCities] = useState([])

const [flights, setFlights] = useState() 

const [findDestination, setFindDestination] = useState(false)
const [dates, setDates] = useState({"partida": "", "regresso" : ""})
const [passageiros, setPassageiros] = useState(1)

const [emptyField, setEmptyField]= useState(false)
const [loader, setLoader]= useState(false)
const [showCities, setShowCities]= useState(true)



const findCity = async (city) => {
    if (city.length >= 3) {
        const find = await axios.get(`https://liquid-cristina-itravel-1cbd2cd2.koyeb.app/api/search_airport?city=${city}`)
        setCities(find.data)
        return;
    }
    if (cities != []) {
        setCities([])
    }
}

const validObj= (objValid)=> {

    let emptyObj= Object.values(objValid).some(obj => obj.toString().trim() === '');

    return emptyObj;

}

const consultarVoos = async (e) => {
   // e.prevantDefault()
   const dataToSend = {
       "origem" : origin.code,
       "destino" : destination.code ,
       "partida" : dates.partida,
       "regresso": dates.regresso,
       "passageiros": passageiros
   }

   if(!validObj(dataToSend)){
        setFlights()
        setLoader(true)
        const findFligths = await axios.post(`https://liquid-cristina-itravel-1cbd2cd2.koyeb.app/api/search_fligths`, dataToSend)
        setFlights(findFligths.data)

        setLoader(false)
        setShowCities(false)

   }else{
    setEmptyField(true)
   }
  

}

const destinationChange = (e) => {
    setShowCities(true)
    if (!findDestination) {
        setFindDestination(true)
    }
    const { value } = e.target;
    setDestinationSearch(value)
    findCity(value)

}

const handleChange = (e) => {
    setShowCities(true)
    if (findDestination) {
        setFindDestination(false)
    }
    
    const { value } = e.target;
    setOriginSearch(value);
    findCity(value);

};

const selectecCity = (city) => {
    setShowCities(true)

    if (findDestination) {
        setDestination(city)
        setDestinationSearch(city.name)
    }else {
        setOrigin(city)
        setOriginSearch(city.countryName)
    }
 
}


  return (
    <>
        <section id="theme_search_form">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="theme_search_form_area">
                        <div className="theme_search_form_tabbtn">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="flights-tab" data-bs-toggle="tab"
                                        data-bs-target="#flights" type="button" role="tab" aria-controls="flights"
                                        aria-selected="true"><i className="fas fa-plane-departure"></i>Voos</button>
                                </li>
                                { /*<li className="nav-item" role="presentation">
                                    <button className="nav-link" id="tours-tab" data-bs-toggle="tab" data-bs-target="#tours"
                                        type="button" role="tab" aria-controls="tours" aria-selected="false"><i
                                            className="fas fa-globe"></i>Tours</button>
                                </li>*/ }
                            
                            </ul>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="flights" role="tabpanel"
                                aria-labelledby="flights-tab">
                                
                                <div className="tab-content" id="myTabContent1">
                                    <div className="tab-pane fade show active" id="oneway_flight" role="tabpanel"
                                        aria-labelledby="oneway-tab">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                
                                                <div className="oneway_search_form">
                                                    
                                                        <div className="row">
                                                            <div className="firstField col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div className="flight_Search_boxed">
                                                                    <p>De</p>
                                                                    <input type="text" value={originSearch} onFocus={handleChange} onChange={handleChange} />
                                                                    <span>{origin?.name ?? 'Luanda, Aeroporto 4 de fevereiro...'}</span>
                                                                    <div className="plan_icon_posation">
                                                                        <i className="fas fa-plane-departure"></i>
                                                                    </div>
                                                                </div>

                                                                {(emptyField && originSearch==='') && (
                                                                    <span className="text-danger alertEmptyField">Campo Obrigatório</span>

                                                                )}
                                                               
                                                            </div>
                                                            <div className="firstField col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div className="flight_Search_boxed">
                                                                    <p>Para</p>
                                                                    <input type="text" value={destinationSearch} onFocus={destinationChange}  onChange={destinationChange} />
                                                                    <span>{destination?.name ?? 'LCY, London city airport'} </span>
                                                                    <div className="plan_icon_posation">
                                                                        <i className="fas fa-plane-arrival"></i>
                                                                    </div>
                                                                    <div className="range_plan">
                                                                        <i className="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                                {(emptyField && destinationSearch==="") && (
                                                                    <span className="text-danger alertEmptyField">Campo Obrigatório</span>

                                                                )}
                                                            </div>
                                                            <div className="col-lg-4  col-md-6 col-sm-12 col-12">
                                                                <div className="form_search_date">
                                                                    <div className="flight_Search_boxed date_flex_area">
                                                                        <div
                                                                                className="flight_Search_boxed date_flex_area">
                                                                                <div className="Journey_date">
                                                                                    <p>Partida</p>
                                                                                    <input type="date" onChange={(e) => {setDates({...dates, "partida": e.target.value})}}/>
                                                                                 
                                                                                </div>
                                                                                <div className="Journey_date">
                                                                                    <p>Regresso</p>
                                                                                    <input type="date"  onChange={(e) => {setDates({...dates, "regresso": e.target.value})}} />
                                                                                   
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                <div className='flight_Search_boxed date_flex_area'>
                                                                  <div className="Journey_date">
                                                                    <input type='number' onChange={(e) => setPassageiros(e.target.value)} />
                                                                    <br />
                                                                    <span>passageiros</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='cities'>
                                                                
                                                                {(cities.length && showCities) && cities.map((city, key) => (
                                                                    <div onClick={ () => selectecCity(city)} key={city?.code} className='city-item'>
                                                                        <h3>{city?.name}</h3>
                                                                        <span>{city?.countryName}</span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                               <div className="flight-list">

                                                                {flights ? 
                                                                
                                                                flights.flights.map((flight, key) => (
                              
                                                                    <FlightCard sizePerson={passageiros} cacheKey={flights.cacheKey} key={key} flight={flight} dictionaries={flights?.dictionaries} dates={dates} />                   
                                                                ) 
                                                            
                                                            ):(
                                                                <Loader state={loader}/>

                                                            )}

                                                            {(flights  && !showCities)&&
                                                                flights.flights.length  === 0 &&(
                                                                    <div className="text-warning text-center">Nenhum registro encontrado!</div>
                                                                )                                                           
                                                            }

                                                            </div>
                                                            <div className="top_form_search_button">
                                                                <button className="btn btn_theme btn_md" onClick={consultarVoos}>Pesquisar</button>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="roundtrip" role="tabpanel"
                                        aria-labelledby="roundtrip-tab">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="oneway_search_form">
                                                    <form action="#!">
                                                        <div className="row">
                                                            <div className="col-lg-3  col-md-6 col-sm-12 col-12">
                                                                <div className="flight_Search_boxed">
                                                                    <p>From</p>
                                                                    <input type="text"  />
                                                                    <span>JFK - John F. Kennedy International...</span>
                                                                    <div className="plan_icon_posation">
                                                                        <i className="fas fa-plane-departure"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3  col-md-6 col-sm-12 col-12">
                                                                <div className="flight_Search_boxed">
                                                                    <p>To</p>
                                                                    <input type="text" />
                                                                    <span>LCY, London city airport </span>
                                                                    <div className="plan_icon_posation">
                                                                        <i className="fas fa-plane-arrival"></i>
                                                                    </div>
                                                                    <div className="range_plan">
                                                                        <i className="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4  col-md-6 col-sm-12 col-12">
                                                                <div className="form_search_date">
                                                                    <div className="flight_Search_boxed date_flex_area">
                                                                        <div className="Journey_date">
                                                                            <p></p>
                                                                            <input type="date" />
                                                                            <span>Thursday</span>
                                                                        </div>
                                                                        <div className="Journey_date">
                                                                            <p>Return date</p>
                                                                            <input type="date" />
                                                                            <span>Saturday</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                <div
                                                                    className="flight_Search_boxed dropdown_passenger_area">
                                                                    <p>Passenger, className </p>
                                                                    <div className="dropdown">
                                                                        <button className="dropdown-toggle final-count"
                                                                            data-toggle="dropdown" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            0 Passenger
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown_passenger_info"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <div className="traveller-calulate-persons">
                                                                                <div className="passengers">
                                                                                    <h6>Passengers</h6>
                                                                                    <div className="passengers-types">
                                                                                        <div className="passengers-type">
                                                                                            <div className="text"><span
                                                                                                    className="count pcount">2</span>
                                                                                                <div className="type-label">
                                                                                                    <p>Adult</p>
                                                                                                    <span>12+
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="button-set">
                                                                                                <button type="button"
                                                                                                    className="btn-add">
                                                                                                    <i
                                                                                                        className="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    className="btn-subtract">
                                                                                                    <i
                                                                                                        className="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="passengers-type">
                                                                                            <div className="text"><span
                                                                                                    className="count ccount">0</span>
                                                                                                <div className="type-label">
                                                                                                    <p
                                                                                                        className="fz14 mb-xs-0">
                                                                                                        Children
                                                                                                    </p><span>2
                                                                                                        - Less than 12
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="button-set">
                                                                                                <button type="button"
                                                                                                    className="btn-add-c">
                                                                                                    <i
                                                                                                        className="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    className="btn-subtract-c">
                                                                                                    <i
                                                                                                        className="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="passengers-type">
                                                                                            <div className="text"><span
                                                                                                    className="count incount">0</span>
                                                                                                <div className="type-label">
                                                                                                    <p
                                                                                                        className="fz14 mb-xs-0">
                                                                                                        Infant
                                                                                                    </p><span>Less
                                                                                                        than 2
                                                                                                        yrs</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="button-set">
                                                                                                <button type="button"
                                                                                                    className="btn-add-in">
                                                                                                    <i
                                                                                                        className="fas fa-plus"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    className="btn-subtract-in">
                                                                                                    <i
                                                                                                        className="fas fa-minus"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="cabin-selection">
                                                                                    <h6>Cabin className</h6>
                                                                                    <div className="cabin-list">
                                                                                        <button type="button"
                                                                                            className="label-select-btn">
                                                                                            <span
                                                                                                className="muiButton-label">Economy
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="label-select-btn active">
                                                                                            <span
                                                                                                className="muiButton-label">
                                                                                                Business
                                                                                            </span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="label-select-btn">
                                                                                            <span
                                                                                                className="MuiButton-label">First
                                                                                                className </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span>Business</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="top_form_search_button">
                                                            <button className="btn btn_theme btn_md">Search</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="multi_city" role="tabpanel"
                                        aria-labelledby="multi_city-tab">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="oneway_search_form">
                                                    <form action="#!">
                                                        <div className="multi_city_form_wrapper">
                                                            <div className="multi_city_form">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div className="flight_Search_boxed">
                                                                            <p>From</p>
                                                                            <input type="text" />
                                                                            <span>DAC, Hazrat Shahajalal
                                                                                International...</span>
                                                                            <div className="plan_icon_posation">
                                                                                <i className="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div className="flight_Search_boxed">
                                                                            <p>To</p>
                                                                            <input type="text"/>
                                                                            <span>LCY, London city airport </span>
                                                                            <div className="plan_icon_posation">
                                                                                <i className="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div className="range_plan">
                                                                                <i className="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div className="form_search_date">
                                                                            <div
                                                                                className="flight_Search_boxed date_flex_area">
                                                                                <div className="Journey_date">
                                                                                    <p>Journey date</p>
                                                                                    <input type="date"/>
                                                                                    <span>Thursday</span>
                                                                                </div>
                                                                                <div className="Journey_date">
                                                                                    <p>Return date</p>
                                                                                    <input type="date" />
                                                                                    <span>Saturday</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                        <div
                                                                            className="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>Passenger, className </p>
                                                                            <div className="dropdown">
                                                                                <button
                                                                                    className="dropdown-toggle final-count"
                                                                                    data-toggle="dropdown" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    0 Passenger
                                                                                </button>
                                                                                <div className="dropdown-menu dropdown_passenger_info"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <div
                                                                                        className="traveller-calulate-persons">
                                                                                        <div className="passengers">
                                                                                            <h6>Passengers</h6>
                                                                                            <div
                                                                                                className="passengers-types">
                                                                                                <div
                                                                                                    className="passengers-type">
                                                                                                    <div className="text">
                                                                                                        <span
                                                                                                            className="count pcount">2</span>
                                                                                                        <div
                                                                                                            className="type-label">
                                                                                                            <p>Adult</p>
                                                                                                            <span>12+
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-add">
                                                                                                            <i
                                                                                                                className="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-subtract">
                                                                                                            <i
                                                                                                                className="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="passengers-type">
                                                                                                    <div className="text">
                                                                                                        <span
                                                                                                            className="count ccount">0</span>
                                                                                                        <div
                                                                                                            className="type-label">
                                                                                                            <p
                                                                                                                className="fz14 mb-xs-0">
                                                                                                                Children
                                                                                                            </p><span>2
                                                                                                                - Less
                                                                                                                than 12
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-add-c">
                                                                                                            <i
                                                                                                                className="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-subtract-c">
                                                                                                            <i
                                                                                                                className="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="passengers-type">
                                                                                                    <div className="text">
                                                                                                        <span
                                                                                                            className="count incount">0</span>
                                                                                                        <div
                                                                                                            className="type-label">
                                                                                                            <p
                                                                                                                className="fz14 mb-xs-0">
                                                                                                                Infant
                                                                                                            </p><span>Less
                                                                                                                than 2
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-add-in">
                                                                                                            <i
                                                                                                                className="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-subtract-in">
                                                                                                            <i
                                                                                                                className="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="cabin-selection">
                                                                                            <h6>Cabin className</h6>
                                                                                            <div className="cabin-list">
                                                                                                <button type="button"
                                                                                                    className="label-select-btn">
                                                                                                    <span
                                                                                                        className="muiButton-label">Economy
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    className="label-select-btn active">
                                                                                                    <span
                                                                                                        className="muiButton-label">
                                                                                                        Business
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    className="label-select-btn">
                                                                                                    <span
                                                                                                        className="MuiButton-label">First
                                                                                                        className </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span>Business</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="multi_city_form">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div className="flight_Search_boxed">
                                                                            <p>From</p>
                                                                            <input type="text" />
                                                                            <span>DAC, Hazrat Shahajalal
                                                                                International...</span>
                                                                            <div className="plan_icon_posation">
                                                                                <i className="fas fa-plane-departure"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                        <div className="flight_Search_boxed">
                                                                            <p>To</p>
                                                                            <input type="text" />
                                                                            <span>LCY, London city airport </span>
                                                                            <div className="plan_icon_posation">
                                                                                <i className="fas fa-plane-arrival"></i>
                                                                            </div>
                                                                            <div className="range_plan">
                                                                                <i className="fas fa-exchange-alt"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div className="form_search_date">
                                                                            <div
                                                                                className="flight_Search_boxed date_flex_area">
                                                                                <div className="Journey_date">
                                                                                    <p>Journey date</p>
                                                                                    <input type="date" />
                                                                                    <span>Thursday</span>
                                                                                </div>
                                                                                <div className="Journey_date">
                                                                                    <p>Return date</p>
                                                                                    <input type="date" />
                                                                                    <span>Saturday</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                                        <div
                                                                            className="flight_Search_boxed dropdown_passenger_area">
                                                                            <p>Passenger, className </p>
                                                                            <div className="dropdown">
                                                                                <button
                                                                                    className="dropdown-toggle final-count"
                                                                                    data-toggle="dropdown" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    0 Passenger
                                                                                </button>
                                                                                <div className="dropdown-menu dropdown_passenger_info"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <div
                                                                                        className="traveller-calulate-persons">
                                                                                        <div className="passengers">
                                                                                            <h6>Passengers</h6>
                                                                                            <div
                                                                                                className="passengers-types">
                                                                                                <div
                                                                                                    className="passengers-type">
                                                                                                    <div className="text">
                                                                                                        <span
                                                                                                            className="count pcount">2</span>
                                                                                                        <div
                                                                                                            className="type-label">
                                                                                                            <p>Adult</p>
                                                                                                            <span>12+
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-add">
                                                                                                            <i
                                                                                                                className="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-subtract">
                                                                                                            <i
                                                                                                                className="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="passengers-type">
                                                                                                    <div className="text">
                                                                                                        <span
                                                                                                            className="count ccount">0</span>
                                                                                                        <div
                                                                                                            className="type-label">
                                                                                                            <p
                                                                                                                className="fz14 mb-xs-0">
                                                                                                                Children
                                                                                                            </p><span>2
                                                                                                                - Less
                                                                                                                than 12
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-add-c">
                                                                                                            <i
                                                                                                                className="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-subtract-c">
                                                                                                            <i
                                                                                                                className="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="passengers-type">
                                                                                                    <div className="text">
                                                                                                        <span
                                                                                                            className="count incount">0</span>
                                                                                                        <div
                                                                                                            className="type-label">
                                                                                                            <p
                                                                                                                className="fz14 mb-xs-0">
                                                                                                                Infant
                                                                                                            </p><span>Less
                                                                                                                than 2
                                                                                                                yrs</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="button-set">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-add-in">
                                                                                                            <i
                                                                                                                className="fas fa-plus"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-subtract-in">
                                                                                                            <i
                                                                                                                className="fas fa-minus"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="cabin-selection">
                                                                                            <h6>Cabin className</h6>
                                                                                            <div className="cabin-list">
                                                                                                <button type="button"
                                                                                                    className="label-select-btn">
                                                                                                    <span
                                                                                                        className="muiButton-label">Economy
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    className="label-select-btn active">
                                                                                                    <span
                                                                                                        className="muiButton-label">
                                                                                                        Business
                                                                                                    </span>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    className="label-select-btn">
                                                                                                    <span
                                                                                                        className="MuiButton-label">First
                                                                                                        className </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span>Business</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="add_multy_form">
                                                                    <button type="button" id="addMulticityRow">+ Add
                                                                        another
                                                                        flight</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="top_form_search_button">
                                                            <button className="btn btn_theme btn_md">Search</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tours" role="tabpanel" aria-labelledby="tours-tab">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="tour_search_form">
                                            <form action="#!">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                        <div className="flight_Search_boxed">
                                                            <p>Destination</p>
                                                            <input type="text" placeholder="Where are you going?" />
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div className="form_search_date">
                                                            <div className="flight_Search_boxed date_flex_area">
                                                                <div className="Journey_date">
                                                                    <p>Journey date</p>
                                                                    <input type="date"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                                <div className="Journey_date">
                                                                    <p>Return date</p>
                                                                    <input type="date" />
                                                                    <span>Thursday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div className="flight_Search_boxed dropdown_passenger_area">
                                                            <p>Passenger, className </p>
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    0 Passenger
                                                                </button>
                                                                <div className="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div className="traveller-calulate-persons">
                                                                        <div className="passengers">
                                                                            <h6>Passengers</h6>
                                                                            <div className="passengers-types">
                                                                                <div className="passengers-type">
                                                                                    <div className="text"><span
                                                                                            className="count pcount">2</span>
                                                                                        <div className="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="button-set">
                                                                                        <button type="button"
                                                                                            className="btn-add">
                                                                                            <i className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract">
                                                                                            <i className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="passengers-type">
                                                                                    <div className="text"><span
                                                                                            className="count ccount">0</span>
                                                                                        <div className="type-label">
                                                                                            <p className="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>2
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="button-set">
                                                                                        <button type="button"
                                                                                            className="btn-add-c">
                                                                                            <i className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract-c">
                                                                                            <i className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="passengers-type">
                                                                                    <div className="text"><span
                                                                                            className="count incount">0</span>
                                                                                        <div className="type-label">
                                                                                            <p className="fz14 mb-xs-0">
                                                                                                Infant
                                                                                            </p><span>Less
                                                                                                than 2
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="button-set">
                                                                                        <button type="button"
                                                                                            className="btn-add-in">
                                                                                            <i className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract-in">
                                                                                            <i className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="cabin-selection">
                                                                            <h6>Cabin className</h6>
                                                                            <div className="cabin-list">
                                                                                <button type="button"
                                                                                    className="label-select-btn">
                                                                                    <span
                                                                                        className="muiButton-label">Economy
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    className="label-select-btn active">
                                                                                    <span className="muiButton-label">
                                                                                        Business
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    className="label-select-btn">
                                                                                    <span className="MuiButton-label">First
                                                                                        className </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>Business</span>
                                                        </div>
                                                    </div>
                                                    <div className="top_form_search_button">
                                                        <button className="btn btn_theme btn_md">Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="hotels" role="tabpanel" aria-labelledby="hotels-tab">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="tour_search_form">
                                            <form action="#!">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                        <div className="flight_Search_boxed">
                                                            <p>Destination</p>
                                                            <input type="text" placeholder="Where are you going?" />
                                                            <span>Where are you going?</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div className="form_search_date">
                                                            <div className="flight_Search_boxed date_flex_area">
                                                                <div className="Journey_date">
                                                                    <p>Journey date</p>
                                                                    <input type="date"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                                <div className="Journey_date">
                                                                    <p>Return date</p>
                                                                    <input type="date"/>
                                                                    <span>Thursday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                        <div className="flight_Search_boxed dropdown_passenger_area">
                                                            <p>Passenger, className </p>
                                                            <div className="dropdown">
                                                                <button className="dropdown-toggle final-count"
                                                                    data-toggle="dropdown" type="button"
                                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    0 Passenger
                                                                </button>
                                                                <div className="dropdown-menu dropdown_passenger_info"
                                                                    aria-labelledby="dropdownMenuButton1">
                                                                    <div className="traveller-calulate-persons">
                                                                        <div className="passengers">
                                                                            <h6>Passengers</h6>
                                                                            <div className="passengers-types">
                                                                                <div className="passengers-type">
                                                                                    <div className="text"><span
                                                                                            className="count pcount">2</span>
                                                                                        <div className="type-label">
                                                                                            <p>Adult</p>
                                                                                            <span>12+
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="button-set">
                                                                                        <button type="button"
                                                                                            className="btn-add">
                                                                                            <i className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract">
                                                                                            <i className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="passengers-type">
                                                                                    <div className="text"><span
                                                                                            className="count ccount">0</span>
                                                                                        <div className="type-label">
                                                                                            <p className="fz14 mb-xs-0">
                                                                                                Children
                                                                                            </p><span>2
                                                                                                - Less than 12
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="button-set">
                                                                                        <button type="button"
                                                                                            className="btn-add-c">
                                                                                            <i className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract-c">
                                                                                            <i className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="passengers-type">
                                                                                    <div className="text"><span
                                                                                            className="count incount">0</span>
                                                                                        <div className="type-label">
                                                                                            <p className="fz14 mb-xs-0">
                                                                                                Infant
                                                                                            </p><span>Less
                                                                                                than 2
                                                                                                yrs</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="button-set">
                                                                                        <button type="button"
                                                                                            className="btn-add-in">
                                                                                            <i className="fas fa-plus"></i>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn-subtract-in">
                                                                                            <i className="fas fa-minus"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="cabin-selection">
                                                                            <h6>Cabin className</h6>
                                                                            <div className="cabin-list">
                                                                                <button type="button"
                                                                                    className="label-select-btn">
                                                                                    <span
                                                                                        className="muiButton-label">Economy
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    className="label-select-btn active">
                                                                                    <span className="muiButton-label">
                                                                                        Business
                                                                                    </span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    className="label-select-btn">
                                                                                    <span className="MuiButton-label">First
                                                                                        className </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>Business</span>
                                                        </div>
                                                    </div>
                                                    <div className="top_form_search_button">
                                                        <button className="btn btn_theme btn_md">Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default SearchForm