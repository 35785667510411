import { useState } from 'react';
import './style.css';
import Modal from '../Modal';
import { useNavigate, useParams } from 'react-router-dom';


const FlightCard = ({ flight, dictionaries, cacheKey, sizePerson, dates }) => {

  const navigate = useNavigate();

  const { price, itineraries } = flight;
  const { carriers, aircraft, locations, currencies } = dictionaries;
  const [showItener, setShowItener] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  
  const handleOnclick = () => {

    navigate(`/booking-confirmation`, { state: {flight: flight, cacheKey: cacheKey, sizePerson: sizePerson, dates: dates} });

  };

//  console.log(flight, dictionaries, cacheKey )

  return (
    <div className="flight-card">
      <div className="flight-card-header">
        <h2>Preço: {price.total} {currencies[price.currency]}</h2>
        <p>Base: {price.base} {currencies[price.currency]}</p>
        <p>Total: {price.grandTotal} {currencies[price.currency]}</p>
        <button className="toggle-itinerary-btn" onClick={() => setShowItener(!showItener)}>
          {showItener ? '-' : '+'} Itinerário
        </button>

        <button onClick={handleOnclick} className='book-button'>Reservar</button>

      </div>
      <div className={`itinerary ${showItener ? 'open' : ''}`}>
        {itineraries.map((itinerary, index) => (
          <div key={index} className="segment">
            <h3>Itinerário {index + 1} - Duração: {itinerary.duration}</h3>
            {itinerary.segments.map((segment, segmentIndex) => (
              <div key={segmentIndex}>
                <p><strong>Partida:</strong> {locations[segment.departure.iataCode].cityCode} ({segment.departure.iataCode}) às {segment.departure.at}</p>
                <p><strong>Chegada:</strong> {locations[segment.arrival.iataCode].cityCode} ({segment.arrival.iataCode}) às {segment.arrival.at}</p>
                <p><strong>Aeronave:</strong> {aircraft[segment.aircraft]}</p>
                <p><strong>Companhia:</strong> {carriers[segment.carrierCode]}</p>
                <p><strong>Duração:</strong> {segment.duration}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
        <Modal isOpen={isModalOpen} onClose={toggleModal}/>
    </div>
  );
};

export default FlightCard;
