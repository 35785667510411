import React, {useEffect, useState} from 'react';
import "./style.css"
import BookingTour from '../BookingTour/BookingTour';

import MyPagination  from '../../pagination/MyPagination';


const BookingForm = ({data}) => {


  /**Local de Dados de formulario */  
    const [numPersons, _] = useState(3);
    const [fields, setFields] = useState([]);
    const [persons, setPersons] = useState([]);
    const [flightOffers, setFlightOffers]= useState([])

    
    const [dataSend, setDataSend]= useState(
      {
        partida: data.flight.itineraries[0].segments[0].departure.iataCode,
        destino: data.flight.itineraries[0].segments[0].arrival.iataCode,
        data_ida: data.dates.partida,
        data_regresso: data.dates.regresso,
        num_passageiros: data.sizePerson,
        nome_responsavel: "",
        contato_responsavel: "",
        amadeus_data: {
          data: {
            type: "flight-order",
            flightOffers: [
                {flightOfferPriceData: true}
            ],
            travelers: []
            
          }
            }
        
          }
    )

    
    
    
    const [allData, setAllData]= useState({
      type: "flight-order",
      flightOffers: flightOffers,
      travelers: []
    })
   

  const [travelers, setTravelers]= useState([])
  const [error, setError]= useState({status:false, msg: ''})

  const [currentPage, setCurrentPage] = useState(0)
  const [date, setDate] = useState({day: '', month: '', year: ''})
  const [ticketPrice, setTicketPrice] = useState({total: 0, currency: 'EUR'})


  useEffect(()=>{
    const dt= data.dates.partida.split('-');
    const mts= ['janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    const year= dt[0]
    const month= {
      position: dt[1],
      name: mts[dt[1].toString().replace(/^0+/, '') - 1]
    }
    const day= dt[2]
    setDate({day: day, month: month, year: year})
    

    /*Passagem*/
    const total= data.flight.price.total
    const currency= data.flight.price.currency
    const formatPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
  }).format(total);
  
    setTicketPrice({total: formatPrice, currency: currency})


  }, []);



  const handleSubmit = (event) => {
    event.preventDefault()
    
    let emptyObj= Object.values(persons[currentPage]).some(obj => obj.toString().trim() === '');
    console.log(persons[currentPage])
    alert(emptyObj)
      if(emptyObj){
        setError({status:true, msg: 'Campos obrigatorios'})
        
      }else{
        setError({status:false, msg: 'sucess'})
        
        orderData(persons);
        
       
        alert(`Foram cadastradas ${numPersons} pessoas! Veja no console do navegador. OBS: este codigo está na 'componet/BookingTour linha 64'`)
        setError({status:true, msg: 'sucess'})

        setDataSend({
          ...dataSend,
          nome_responsavel: `${persons[0].firstName} ${persons[0].lastName}`,
          contato_responsavel: persons[0].phoneNumber,
          amadeus_data: {
            data: {...allData}
          }
        
        })
      }
  };
 

 const handleInputChange = (event) => {
   const newPersons = persons;
   newPersons[Number(event.target.id)] = {...newPersons[Number(event.target.id)], [event.target.name]: event.target.value};
   
   setFields(newPersons)
   setPersons(newPersons);
  };


  useEffect(() => {
      // Inicializa o array de pessoas quando o número muda
      let test= []
      const struct= {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        email: '',
        phoneNumber: '',
        passportNumber: '',
        passportIssuanceDate: ''
      }
      
      Array.from({ length: numPersons }).map((_)=>{
        
        if(test.length <= numPersons){
          test.push(struct)
        }
        
      })     
      setPersons(test) 
      setFields(test)

       
    }, []);
    
    const orderData = (arrayData) => {
      
      if(arrayData.length > Number(travelers.length)){

        arrayData.map((data, index)=>{
          
          setTravelers( (traveler)=>[
            ...traveler,
            {
              "id": index + 1,
              "dateOfBirth": data.dateOfBirth,
              "name": {
                "firstName": data.firstName,
            "lastName": data.lastName
            },
            "gender": data.gender,
            "contact": {
              "emailAddress": data.email,
              "phones": [
              {
                "deviceType": "MOBILE",
                "countryCallingCode": '+244',
                "number": data.phoneNumber,
              }
            ]
          },
          "documents": [
            {
              "documentType": "PASSPORT",
              "birthPlace": "Madrid",
              "issuanceLocation": "Madrid",
              "issuanceDate": data.passportIssuanceDate,
              "number": data.passportNumber,
              "expiryDate": "2025-04-14",
              "issuanceCountry": "ES",
              "validityCountry": "ES",
              "nationality": "ES",
              "holder": true
            }
          ]
        } 
      ])
    })

    setAllData({...allData, travelers: [...travelers]})
    
      }else{
        setTravelers((traveler)=>[...traveler])
        console.log(travelers)
      }
      
    };

    const validForm = () => {

      let emptyObj= Object.values(persons[currentPage]).some(obj => obj.toString().trim() === '');
      if(emptyObj){
        setError({status:true, msg: 'Campo obrigatório!'})
      }else{
        
        const newPersons = persons;
        newPersons[Number(currentPage)] = {...newPersons[Number(currentPage)]};  
        
        setCurrentPage(Number(currentPage + 1))
        setError({status:false, msg: 'sucess'})
        
      }

    };

    console.log(allData, dataSend)

    
  return (
    <section id="tour_booking_submission" className="section_padding">
      <div className="container">
        <div className="row ">
          <div className="col-lg-8">
            
            <div className="tour_booking_form_box mb-4 ">

              <form >
              <h3 className="heading_theme">Sua Informação</h3>
              
               <div className="container_form">

                {
                  Array.from({ length: numPersons }).slice(0, numPersons ).map((_, index) =>  (
                    
                      <BookingTour handleInputChange={handleInputChange} index={index} currentPage={currentPage} pecent={currentPage * 100} key={index} error={error} fields={fields}/>
                   
                  ))
                }

              </div>

              {numPersons > 1 &&(
                      
                <MyPagination currentPage={currentPage} sizePerson={numPersons}/>

                    )}


                  {Number(numPersons) === Number(currentPage + 1)?(
                    <div style={{display:'flex',justifyContent: "space-between", gap: '1rem'}}>
                    {Number(currentPage + 1) > 1 &&(
                      <button onClick={()=> setCurrentPage(Number(currentPage - 1))} type="button" className="btn btn-warning">Anterior</button>

                    )}
                      <button onClick={handleSubmit} type="button" className="btn btn-primary">Enviar</button>
                    </div>
                    
                  ):(
                    <div style={{display:'flex', justifyContent: "flex-start", gap: '1rem'}}>

                      <button
                      type="button" 
                      className={`${Number(currentPage ) === 0? 'disabled text-primary' : null} btn btn-small btn-warning`}
                      onClick={()=> setCurrentPage(Number(currentPage - 1))}

                      >Anterior</button>
                      <button onClick={validForm} type="button" className="btn btn-warning">Proximo</button>
                    </div>

                  )}
              </form>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="tour_details_right_sidebar_wrapper">
              <div className="tour_detail_right_sidebar">
                <div className="tour_details_right_boxed">
                  <div className="tour_details_right_box_heading">
                    <h3>Detalhes da Reserva</h3>
                  </div>
                  <div className="tour_booking_amount_area">
                    <ul>
                      <li>ID da Reserva: <span>#RB5783GH</span></li>
                      <li>Data da Reserva: <span>{date.day} de {date.month.name} de {date.year}</span></li>
                      <li>Método de Pagamento: <span>Transferência Bancária</span></li>
                      <li>Status da Reserva: <span>Sucesso</span></li>
                    </ul>
                    <ul>
                      <li>Preço Adulto x {numPersons} <span>{ticketPrice.total}</span></li>
                      <li className="remove_coupon_tour">Desconto <span>0%</span></li>
                      <li>Taxa <span>0%</span></li>
                    </ul>
                    <div className="tour_bokking_subtotal_area">
                      <h6 className="remove_coupon_tour">Subtotal <span>{ticketPrice.total}</span></h6>
                    </div>
                    <div className="coupon_add_area">
                      <h6><span className="remove_coupon_tour">Remover</span> Código do Cupom (OFF 5000)
                        <span>$00.00</span>
                      </h6>
                    </div>
                    <div className="total_subtotal_booking">
                      <h6 className="remove_coupon_tour">Total <span>{ticketPrice.total}</span> </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingForm;
