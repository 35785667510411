import React from 'react'
// Import Link
import { Link } from 'react-router-dom'
// import Img
import Img from '../../../assets/img/about/abour_right.png'


const AboutArea = () => {
  return (
    <>
     <section id="about_us_top" className="section_padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="about_us_left">
                        <h5>Sobre nós</h5>
                       <h2>A Melhor Agência de Viagens chegou !</h2>
                        <p>A iTravelao, uma empresa de origem angolana, se dedica a digitalizar as vendas de passagens e reservas de hotéis, trazendo comodidade e inovação para os nossos clientes. Desde a nossa fundação em 2000, temos nos comprometido a oferecer as melhores soluções de viagem, sempre com um serviço de excelência.</p>
                        <p>Nosso objetivo é tornar as suas viagens mais fáceis e acessíveis, através de uma plataforma online intuitiva e segura. Com a iTravelao, você pode planejar suas férias dos sonhos ou viagens de negócios de forma rápida e prática, com a garantia de encontrar as melhores ofertas e serviços.</p>
                        <p>Acreditamos que viajar é uma das melhores formas de expandir horizontes e viver experiências únicas. Por isso, trabalhamos incansavelmente para proporcionar a você a melhor experiência em todas as etapas da sua viagem. Seja qual for o destino, conte com a iTravelao para levá-lo até lá.</p>
                        <Link to="/" className="btn btn_theme btn_md">Faça reserva</Link>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="about_us_right">
                        <img src={Img} alt="img" />
                    </div>
                </div>

            </div>
        </div>
    </section>

    </>
  )
}

export default AboutArea